import type { IRequestOptions, ApiRequestOptions as TApiRequestOptions } from 'o365.pwa.declaration.sw.apiRequestOptions.ApiRequestOptions.d.ts';
import type { ApiFileRoute } from 'o365.pwa.declaration.sw.strategies.api.file.strategy.ts';
import type { Request } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';

declare module 'o365.pwa.declaration.sw.apiRequestOptions.ApiFileRequestOptions.d.ts' {
    export type OperationType = 'VIEW' | 'DOWNLOAD' | 'UPLOAD' | 'OLD-CHUNK-UPLOAD' | 'CHUNK-UPLOAD' | 'CHUNK-INITIATE' | 'CHUNK-SETCRC' | 'UPLOAD-PROGRESS' | 'PDF-VIEW' | 'PDF-DOWNLOAD';

    export interface IUrlParams { }

    export interface IUrlView extends IUrlParams {
        option: string;
        appId: string;
        databaseId: string;
        objectStoreId: string;
        primKey: string;
        fileName?: string;
    }

    export interface IUrlDownload extends IUrlParams {
        option: string;
        appId: string;
        databaseId: string;
        objectStoreId: string;
        primKey: string;
        fileName?: string;
    }

    export interface IUrlUpload extends IUrlParams {
        option: string;
        appId: string;
        databaseId: string;
        objectStoreId: string;
        primKey: string;
        fileName?: string;
    }

    export interface IUrlOldChunkUpload extends IUrlParams {
        appId: string;
        databaseId: string;
        objectStoreId: string;
        uploadRef?: string;
        primKey?: string;
    }
    export interface IUrlChunkUpload extends IUrlParams {
        appId: string;
        databaseId: string;
        objectStoreId: string;
        uploadRef?: string;
        primKey?: string;
    }

    export interface IUrlChunkInitiate extends IUrlParams {
        appId: string;
        databaseId: string;
        objectStoreId: string;
        primKey?: string;
    }
    export interface IUrlChunkSetCRC {
        uploadRef: string;
    }
    export interface IUrlPdfView extends IUrlParams {
        option: string;
        appId: string;
        databaseId: string;
        objectStoreId: string;
        primKey: string;
        fileName?: string;
    }

    export interface IUrlPdfDownload extends IUrlParams {
        option: string;
        appId: string;
        databaseId: string;
        objectStoreId: string;
        primKey: string;
        fileName?: string;
    }

    export interface IApiFileRequestOptions<T extends IApiFileRequestStatic> {
        appId: string;
        headers: Map<string, Set<string>>;
        options: T;
    }

    export interface IApiFileRequestStatic {
        operation: OperationType;
        primKey: string;
        viewName: string;
        uniqueTable?: string;
        appId: string;
        objectStoreId: string;
        databaseId: string;
        appIdOverride: string;
        databaseIdOverride: string;
        objectStoreIdOverride: string;
        [key: string]: unknown;
    }

    export interface IApiFileRequestViewOperationOptions extends IApiFileRequestStatic {
        operation: 'VIEW';
        appId: string;
        objectStoreId: string;
        databaseId: string;
        appIdOverride: string;
        databaseIdOverride: string;
        objectStoreIdOverride: string;
        primKey: string;
        fileName?: string;
    }

    export interface IApiFileRequestDownloadOperationOptions extends IApiFileRequestStatic {
        operation: 'DOWNLOAD';
        primKey: string;
        appId: string;
        objectStoreId: string;
        databaseId: string;
        appIdOverride: string;
        databaseIdOverride: string;
        objectStoreIdOverride: string;
        fileName?: string;
    }

    export interface IApiFileRequestUploadOperationOptions extends IApiFileRequestStatic {
        operation: 'UPLOAD';
        data: Blob | ArrayBuffer;
        primKey: string;
        mimeType: string;
        filename: string;
        filesize: number;
        extension: string;
        pdfRef?: string;
        thumbnailRef?: string;
        extraValues?: {
            [key: string]: unknown;
        }
    }

    export interface IApiFileRequestChunkUploadOperationOptions extends IApiFileRequestStatic {
        operation: 'CHUNK-UPLOAD';
        data: Blob;
        mimeType: string;
        filename: string;
        filesize: number;
        extension: string;
        pdfRef?: string;
        thumbnailRef?: string;
        uploadRef?: string;
        appID: string;
        extraValues: {
            [key: string]: unknown;
        }
    }
    export interface IApiFileRequestChunkUploadInitiateOperationOptions extends IApiFileRequestStatic {
        operation: 'CHUNK-INITIATE';
        dataSourceId: string;
        viewName: string;
        distinctRows: boolean;
        skip: number;
        fields: Array<{
            name: string;
            sortOrder?: number;
            sortDirection?: "asc" | "desc";
        }>;
        loadRecents: boolean;
        maxRecords: number;
        whereClause: string;
        masterDetailString: string;
        optimisticLocking: boolean;
        uniqueTable: string;
        values: {
            Item_ID: number;
        };
        FileName: string;
        FileSize: number;
    }
    export interface IApiFileRequestChunkUploadSetCrcOperationOptions extends IApiFileRequestStatic {
        FileCRC: number;
        FileName: string;
        FileSize: number;
        UploadRef: string;
        ViewName: string;
    }

    export interface IFileCrudUploadProgressOperationOptions extends IApiFileRequestStatic {
        operation: 'UPLOAD-PROGRESS';
    }

    export interface IFileCrudPdfViewOperationOptions extends IApiFileRequestStatic {
        operation: 'PDF-VIEW';
        appId: string;
        objectStoreId: string;
        databaseId: string;
        appIdOverride: string;
        databaseIdOverride: string;
        objectStoreIdOverride: string;
        primKey: string;
        fileName?: string;
    }

    export interface IFileCrudPdfDownloadOperationOptions extends IApiFileRequestStatic {
        operation: 'PDF-DOWNLOAD';
        appId: string;
        objectStoreId: string;
        databaseId: string;
        appIdOverride: string;
        databaseIdOverride: string;
        objectStoreIdOverride: string;
        primKey: string;
        fileName?: string;
    }

    export class ApiFileRequestOptions<T extends IApiFileRequestStatic> implements IRequestOptions {
        readonly appId: string;
        readonly options: T;
        readonly headers: Map<string, Set<string>>;

        constructor(options: IApiFileRequestOptions<T>);

        static fromRequest<T extends IApiFileRequestStatic>(request: Request, apiFileRoute: ApiFileRoute): Promise<TApiRequestOptions<IApiFileRequestOptions<T>>>;

        static parseFormData(formData: FormData): any; // The return type should be specified more precisely if possible

        static fileToBlob(file: File): Blob;

        static getFileExtension(name: string): string | null;

        static getMimeTypeFromExtension(extension: string): string | undefined;

        static getUrlParams(url: string, apiFileRoute: 'VIEW'): IUrlView;
        static getUrlParams(url: string, apiFileRoute: 'DOWNLOAD'): IUrlDownload;
        static getUrlParams(url: string, apiFileRoute: 'UPLOAD'): IUrlUpload;
        static getUrlParams(url: string, apiFileRoute: 'OLD-CHUNK-UPLOAD'): IUrlOldChunkUpload;
        static getUrlParams(url: string, apiFileRoute: 'CHUNK-UPLOAD'): IUrlChunkUpload;
        static getUrlParams(url: string, apiFileRoute: 'CHUNK-INITATE'): IUrlChunkInitiate;
        static getUrlParams(url: string, apiFileRoute: 'CHUNK-SET-CRC32'): IUrlChunkSetCRC;
        static getUrlParams(url: string, apiFileRoute: 'PDF-VIEW'): IUrlPdfView;
        static getUrlParams(url: string, apiFileRoute: 'PDF-DOWNLOAD'): IUrlPdfDownload;
        static getUrlParams(url: string, apiFileRoute: ApiFileRoute): IUrlParams;

        static getUrlParameters(url: string, regex: RegExp): RegExpMatchArray | null;

        getRequestData(requestData: Request): Promise<Record<string, any>>;

        static getFile(request: Request): Promise<Blob | null>;
    }
}